import React from 'react';
import Header from '../components/header';
import Blogs_content from '../components/blogs_content';


function Blogs() {

    return (
      
      <div>
        
        <Header />
        <Blogs_content page="blogs" />
      
      </div>

    );
  }
  
export default Blogs;